import 'zone.js';
import { features } from './feature-toggle';

export const featConfig: FeatureOptions = {
  opensearchDynamicFiltersActive: false,
  '*': true,
};

type FeatureOptions = {
  [key: string]: boolean;
};

export const environment = {
  environmentName: 'dev',
  appName: 'Portal',
  production: false,
  vendorApiBaseUrl: 'https://api.sustainment.app/vendorprofile/dev/api/',
  projectsApiBaseUrl: 'https://api.sustainment.app/projects/dev/api/',
  identityApiBaseUrl: 'https://api.sustainment.app/identity/dev/api/',
  mediaApiBaseUrl: 'https://media-dev.sustainment.app/api/',
  emailValidationApiUrl: 'https://public-api-dev.sustainment.io/email-valid',
  interactionsApiUrl: 'https://api.sustainment.app/interactions/dev/api/',
  searchMiddlewareApiBaseUrl:
    'https://api.sustainment.app/searchvendors/dev/api',
  mapboxApiUrl: 'https://api.mapbox.com/',
  platFormCommuncationsUrl:
    'https://api.sustainment.app/communications/dev/api',
  okta: {
    baseUrl: 'https://sustainmenttech.oktapreview.com',
    clientId: '0oacclg15ijQSmVkq1d7',
    googleId: '0oa5n92wxRhnU99bD1d6',
    facebookId: '0oa5ru6rv8OWZYmkW1d6',
    linkedinId: '0oa5rv898Ay27cNu41d6',
  },
  timeoutWarning: 60 * 1000 * 10,
  featureConfig: featConfig,
  featureConfigNew: features,
  datadog: {
    clientToken: 'pub0b66f38d73f15bd1fb8cdc68c5c2c548',
    site: 'datadoghq.com',
    service: 'Portal-dev',
  },
  boxEnterpriseId: '831410616',
  portalGAToken: '',
  segmentWriteKey: 'E2uzVsjew4RiqnFZcqFSMV2KRoDyN4c5',
};
